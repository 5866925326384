<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="roleForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="角色名称" prop="name">
          <a-input
            placeholder="请输入"
            v-model="info.name"
            :disabled="info.systemLevel == 1"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="角色说明">
          <a-textarea
            placeholder="请输入角色说明"
            :rows="4"
            v-model="info.description"
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="权限设置" required>
          <span class="setBtn" @click="handleRoleSet">设置</span>
        </a-form-model-item>
        <a-form-model-item label="后台管理角色">
          <a-switch
            v-model="info.admin"
            :disabled="info.systemLevel == 1"
            checked-children="是"
            un-checked-children="否"
          />
        </a-form-model-item>
        <a-form-model-item label="角色状态">
          <a-switch
            v-model="info.status"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 6 }">
          <pxkeji-btn name="确认" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="设置权限"
      v-model="role.visible"
      :footer="false"
      destroyOnClose
      width="900px"
    >
      <allRole @callbackMethod="save" :checkAuth="info.authorize" />
    </a-modal>
  </a-spin>
</template>

<script>
import allRole from "./allRole.vue";
let _this;
export default {
  name: "roleinfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      info: {
        name: "",
        description: "",
        authorize: [],
        admin: true,
        status: true,
      },
      rules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
      },
      role: {
        visible: false,
      },
      allRemoteTypelist: [],
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id) {
      _this.getInfo();
    }
  },
  components: {
    allRole,
  },
  methods: {
    cancelClick() {
      this.$emit("callbackMethod");
    },
    getInfo() {
      _this.isloading = true;
      _this.$api.role
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.info.status = _this.$commen.changeIntBoolean(res.data.status);
            _this.info.admin = _this.$commen.changeIntBoolean(res.data.admin);
            _this.info.authorize = res.data.authorize
              ? res.data.authorize.split(",")
              : [];
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**角色设置 */
    handleRoleSet() {
      _this.role.visible = true;
    },
    save(roleList) {
      _this.info.authorize = roleList;
      _this.role.visible = false;
    },
    handleSubmit() {
      _this.$refs.roleForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          if (_this.infos.id) {
            _this.$api.role
              .update(
                _this.infos.id,
                _this.info.name,
                _this.info.description ? _this.info.description : "",
                _this.info.authorize.join(","),
                _this.$commen.changeBooleanInt(_this.info.status),
                _this.$commen.changeBooleanInt(_this.info.admin)
              )
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(
                    res.errorMsg ? res.errorMsg : "保存成功"
                  );
                  _this.$emit("callbackMethod");
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
          } else {
            _this.$api.role
              .save(
                _this.info.name,
                _this.info.description ? _this.info.description : "",
                _this.info.authorize.join(","),
                _this.$commen.changeBooleanInt(_this.info.status),
                _this.$commen.changeBooleanInt(_this.info.admin)
              )
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(
                    res.errorMsg ? res.errorMsg : "保存成功"
                  );
                  _this.$emit("callbackMethod");
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.setBtn {
  cursor: pointer;
  color: #1890ff;
}
</style>