<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="角色名称">
              <a-input placeholder="请输入" v-model="search.roleName" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo({ id: 0 })"
          type="primary"
          :roles="['角色-添加']"
        />
        <pxkeji-btn
          name="批量启用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="batchEnable"
          :roles="['角色-禁用启用']"
        />
        <pxkeji-btn
          name="批量禁用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="batchDisEnable"
          :roles="['角色-禁用启用']"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        bordered
        size="small"
        :columns="list.columns"
        :pagination="false"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="admin" slot-scope="admin">
          <a-tag v-if="admin" color="green">后台</a-tag>
          <a-tag v-else color="red">前台</a-tag>
        </span>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            size="small"
            type="primary"
            @click="showInfo(action)"
            :roles="['角色-编辑']"
          />
          <a-popconfirm
            v-if="action.status == 1"
            title="是否确认禁用此角色？"
            @confirm="handleProhibit(action.id, 0)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="禁用"
              size="small"
              type="danger"
              :roles="['角色-禁用启用']"
            />
          </a-popconfirm>
          <a-popconfirm
            v-else
            title="是否确认启用此角色？"
            @confirm="handleProhibit(action.id, 1)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn name="启用" size="small" :roles="['角色-禁用启用']" />
          </a-popconfirm>
          <a-popconfirm
            title="是否确认删除此角色？"
            @confirm="delInfo(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              v-if="action.systemLevel != 1"
              :roles="['角色-删除']"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      :footer="false"
      destroyOnClose
      width="800px"
      :maskClosable="false"
    >
      <info @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import info from "./roleInfo.vue";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    align: "left",
    title: "角色名称",
  },
  {
    dataIndex: "addTime",
    key: "addTime",
    align: "center",
    title: "创建时间",
  },
  {
    dataIndex: "admin",
    key: "admin",
    align: "center",
    title: "角色",
    scopedSlots: { customRender: "admin" },
  },
  {
    dataIndex: "status",
    key: "status",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    widht: 300,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "rolelist",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        tableHeight: window.innerHeight - 260,
      },
      //查询条件
      search: {
        roleName: "",
      },
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  components: {
    info: info,
  },
  mounted: function () {
    _this = this;
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 300;
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      _this.isloading = true;
      _this.cancelSelect();
      _this.$api.role
        .getAll(_this.search.roleName)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    searchList() {
      _this.GetList();
    },
    showInfo(info) {
      //打开弹出框
      _this.addedit.visible = true;
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
    },
    /*批量启用 */
    batchEnable() {
      _this.$confirm({
        title: "提示",
        content: `您确定要启用所选的角色吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.handleProhibit(_this.selectRows.join(","), 1);
        },
        onCancel() {},
      });
    },
    /*批量禁用 */
    batchDisEnable() {
      _this.$confirm({
        title: "提示",
        content: `您确定要禁用所选的角色吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.handleProhibit(_this.selectRows.join(","), 0);
        },
        onCancel() {},
      });
    },
    /*禁用启用 */
    handleProhibit(ids, status) {
      _this.isloading = true;
      _this.$api.role
        .chanageStatus(ids, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.role
        .del(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

