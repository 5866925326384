<template>
  <div>
    <div class="role_item">
      <a-checkbox
        :checked="checkAll"
        @change="onCheckAll(!checkAll)"
        :indeterminate="indeterminateAll"
      >
        全选
      </a-checkbox>
    </div>
    <div v-for="(permiss, index) in permissionList" :key="index">
      <div
        :style="{
          borderBottom: '1px solid #E9E9E9',
          marginBottom: '10px',
          paddingBottom: '10px',
        }"
      >
        <div :style="{ fontWeight: 'bold', marginBottom: '5px' }">
          <a-checkbox
            @change="onCheckAllChange(index, permiss.type, $event)"
            :checked="permiss.isChecked"
            :indeterminate="permiss.indeterminate"
            >{{ permiss.type }}</a-checkbox
          >
        </div>
        <a-checkbox-group
          :options="permiss.value"
          v-model="checkedList[permiss.type]"
          @change="onCheckChildChange(index, permiss.type, $event)"
        />
      </div>
    </div>

    <a-form-item :wrapper-col="{ span: 24, offset: 10 }">
      <pxkeji-btn name="确认" type="primary" @click="handleSubmit" />
    </a-form-item>
  </div>
</template>

<script>
let _this;
export default {
  props: {
    id: Number,
    checkAuth: Array,
  },
  data() {
    return {
      isloading: false,
      permissionList: [],
      checkedList: {},
      checkAll: false,
      indeterminateAll: false,
    };
  },
  mounted() {
    _this = this;
    _this.getInfo();
  },
  methods: {
    getInfo() {
      _this.isloading = true;
      _this.$api.role
        .getAllClaims("admin")
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.permissionList = res.data;
            _this.permissionList.forEach((p) => {
              p.isChecked = false;
              p.indeterminate = false;
              let num = 0;
              let checkItems = [];
              if (p.value) {
                p.value.forEach((v) => {
                  let result = _this.checkAuth.filter(
                    (c) => c == `${p.type}-${v}`
                  );
                  if (result && result.length > 0) {
                    num++;
                    checkItems.push(v);
                  }
                });
              }

              if (num > 0) {
                _this.$set(_this.checkedList, p.type, checkItems);
                p.indeterminate = true;
                if (num == p.value.length) {
                  p.indeterminate = false;
                  p.isChecked = true;
                }
              }
              if (!_this.indeterminateAll && num > 0) {
                _this.indeterminateAll = true;
              }
            });
            _this.refreshCheckAllStatus();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    refreshCheckAllStatus() {
      let checkTypeNum = _this.permissionList.filter((p) => p.isChecked).length;
      _this.checkAll = checkTypeNum == _this.permissionList.length;
      _this.indeterminateAll = checkTypeNum > 0 && !_this.checkAll;
    },
    onCheckAll(val) {
      _this.checkAll = val;
      _this.indeterminateAll = false;
      if (val) {
        _this.permissionList.forEach((p) => {
          p.isChecked = true;
          p.indeterminate = false;
          _this.$set(_this.checkedList, p.type, p.value);
        });
      } else {
        _this.permissionList.forEach((p) => {
          p.isChecked = false;
          p.indeterminate = false;
          _this.$set(_this.checkedList, p.type, []);
        });
      }
    },
    onCheckAllChange(index, type, e) {
      _this.permissionList[index].isChecked = e.target.checked;
      _this.permissionList[index].indeterminate = false;
      if (e.target.checked) {
        _this.$set(_this.checkedList, type, _this.permissionList[index].value);
      } else {
        _this.$set(_this.checkedList, type, undefined);
      }
      _this.refreshCheckAllStatus();
    },
    onCheckChildChange(index, type, e) {
      _this.checkedList[type] = e;
      let allCheck = e.length == _this.permissionList[index].value.length;
      _this.permissionList[index].isChecked = allCheck;
      _this.permissionList[index].indeterminate = e.length > 0 && !allCheck;
      _this.refreshCheckAllStatus();
    },
    handleSubmit() {
      let result = [];
      for (const key in _this.checkedList) {
        const value = _this.checkedList[key];
        if (value && value.length > 0) {
          value.forEach((v) => {
            result.push(`${key}-${v}`);
          });
        }
      }
      if (result.length == 0) {
        _this.$message.error("请选择权限");
        return;
      } else {
        _this.$emit("callbackMethod", result);
      }
    },
  },
};
</script>
<style>
.setBtn {
  cursor: pointer;
  color: #1890ff;
}
</style>